import {endpoints} from './endpoints'
import request from '@/helpers/request'

export function getComplaintsByCompany(data){
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getComplaintsByCompany,
			method: 'get',
			params: data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			});
	})
}

export function getComplaintTypes(data){
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getComplaintTypes,
			method: 'get',
			params: data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			});
	})
}