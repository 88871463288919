import Vue from 'vue'
import Router from 'vue-router'
import Reportes from '../views/Reportes.vue'
import MapScreen from '../components/MapComplaints/MapScreen.vue'

Vue.use(Router)

export default new Router({
    mode: 'history', // https://router.vuejs.org/api/#mode
    linkActiveClass: 'active',
    scrollBehavior: () => ({ y: 0 }),
    routes: configRoutes()
  })


  function configRoutes () {
    return [
  {
    path: '/',
    name: 'MapScreen',
    component: MapScreen
  },
  {
    path: '/reportes',
    name: 'Reportes',
    component: Reportes
  }
]
}