<template>
  <v-app>
    <v-main>
      <WebContainer/>
    </v-main>
  </v-app>
</template>

<script>
import WebContainer from './components/Layout/WebContainer';

export default {
  name: 'App',

  components: {
    WebContainer
  },

  data: () => ({
    //
  }),
	mounted(){
		this.$store.dispatch('getComplaints')
		this.$store.dispatch('getComplaintTypes')
	}
};
</script>
