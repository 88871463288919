import { getComplaintsByCompany, getComplaintTypes } from '@/helpers/api/complaints'

const complaintsStore = {
  state: () => ({
		complaintsAll: [],
		complaints: [],
		complaintTypes: [],
		filter: false
	}),
  mutations: {
		setComplaints(state, complaints){
			state.complaints = complaints
		},
		setComplaintsAll(state, complaints){
			state.complaintsAll = complaints
		},
		setComplaintTypes(state, complaintTypes){
			state.complaintTypes = complaintTypes
		},
		setFilter(state, filter){
			state.filter = filter
		}
	},
  actions: {
		async filterComplaints(context, query){
			let complaints = context.state.complaintsAll
			if(query.complaint_types){
				complaints = complaints.filter((item) => {
					return query.complaint_types.includes(item.type.id)
				})
			}
			context.commit('setComplaints', complaints)
			context.commit('setFilter', true)
		},
		async getComplaints(context){
			const complaints = await getComplaintsByCompany({company_id: 2})
			console.log(complaints.complaints)
			context.commit('setComplaints', complaints.complaints)
			context.commit('setComplaintsAll', complaints.complaints)
		},
		async getComplaintTypes(context){
			const types = await getComplaintTypes({company_id: 2})
			context.commit('setComplaintTypes', types.complaint_types)
		}
	}
}

export default complaintsStore