<template>
	<v-navigation-drawer
		:mini-variant.sync="isMini"
		expand-on-hover
		absolute
		right
		:width="300"
		mini-variant-width="80px"
		class="menu"
	>
		<v-list class="px-0 py-3">
			<v-list-item class="justify-content-space-between">
				<div class="primary--text font-weight-bold body-1 d-flex flex-grow-1" v-if="!isMini">Listado de Reportes</div>
				<v-btn
					icon
					@click.stop="isMini = !isMini; getAllComplaints();"
				>
					<v-icon v-if="isMini">mdi-chevron-left</v-icon>
					<v-icon v-else>mdi-chevron-right</v-icon>
				</v-btn>
			</v-list-item>
		</v-list>
		<v-divider></v-divider>
		<div>
      <v-list
        nav
        dense
		class="pa-0"
      >
        <v-list-item-group
		v-model="type"
        color="primary"
        >
					<v-list-item @click.stop="getAllComplaints(); isMini = !isMini;" :value="99" link class="px-4 py-2 mb-0 rounded-0" :class="isMini ? 'justify-center':''">
						<v-list-item-icon :class="isMini ? '':'mr-4'">
							<v-icon>mdi-view-grid</v-icon>
						</v-list-item-icon>
						<v-list-item-title class="font-weight-bold body-2">Todas las denuncias</v-list-item-title>
					</v-list-item>
					<v-divider class="mb-2"></v-divider>
          <v-list-item
            v-for="(item, i) in $store.state.complaintsStore.complaintTypes"
            :key="i"
			link
			class="px-4 py-1 mb-0 rounded-0"
			@click.stop="filterType(item); isMini = !isMini;"
          >
            <v-list-item-icon class="mr-4 align-self-center">
              <v-img :src="item.main_image" contain width="20" class="complaint-type-icon"></v-img>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="font-weight-regular" v-text="item.title"></v-list-item-title>
            </v-list-item-content>
						<v-list-item-action>
							<div class="primary--text font-weight-bold title">{{countComplaints(item.id)}}</div>
						</v-list-item-action>
          </v-list-item>
        </v-list-item-group>
      </v-list>
		</div>
	</v-navigation-drawer>
</template>

<script>
export default {
	name: 'Filters',
  data () {
    return {
			isMini: true,
			type: 99
    }
  },
	methods:{
		filterType(type){
			!type.subtypes ? this.$store.dispatch('filterComplaints', {complaint_types: [type.id]}) : this.$store.commit('setComplaintTypes', type.subtypes)
		},
		countComplaints(type){
			if(type == 99){
				return this.$store.state.complaintsStore.complaintsAll.length
			}
			const filtered = this.$store.state.complaintsStore.complaintsAll.filter((item) => {
				return item.type.id == type
			})
			return filtered.length
		},
		getAllComplaints() {
			this.$store.dispatch('getComplaints');
			this.$store.dispatch('getComplaintTypes');
		},
	}
}
</script>

<style scoped>
	.complaint-type-icon{
		filter: brightness(0) saturate(100%) invert(61%) sepia(8%) saturate(341%) hue-rotate(182deg) brightness(91%) contrast(92%);
	}
	.menu {
		background-color: #F7B53F;
	}
</style>