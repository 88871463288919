<template>
  <div v-if="selectedComplaint">
    <v-card style="border-radius: 10px;">
      <div class="title-container" >
          <img
            class="marker-icon"
            :src="selectedComplaint.type.marker_icon"
            alt="videorecord"
          />
        <h5 class="card-title" >
          {{ selectedComplaint.type.title }}
        </h5>

        <v-btn class="ml-auto mr-5" icon @click="toggleDialog">
      <v-icon  style="font-size:44px; color:black;">
        mdi-close-circle-outline
      </v-icon>
    </v-btn>
      </div>

      <section class="content">
        <v-row>
          <v-col cols="12" md="6">
        <iframe
          class="content-item"
          :src="selectedComplaint.videorecord.recording_link"
          frameborder="0"
          allowfullscreen
        ></iframe>
        </v-col>

        <v-col cols="12" md="6">
          <GmapMap
          :center="map_center"
          :zoom="$store.state.generalStore.mapCenter.zoom"
          :options="{ streetViewControl: false, mapTypeControl: false }"
          class="content-item"
        >
          <GmapCluster
            ref="markercluster"
            :averageCenter="true"
            :zoomOnClick="true"
            :ignoreHidden="true"
          >
            <GmapMarker
              :position="{
                lat: selectedComplaint.location.latitude,
                lng: selectedComplaint.location.longitude,
              }"
              :clickable="true"
              :icon="{
                url: selectedComplaint.type.marker_icon,
                scaledSize: { width: 36, height: 60 },
              }"
            />
          </GmapCluster>
          </GmapMap>
        </v-col>
        </v-row>
      </section>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  name: "videoPreview",
  props: {
    selectedComplaint: {
      type: Object,
      default: () => {},
    },
    toggleDialog: Function
  },
  data() {
    return {};
  },
  computed: {
    date() {
      return moment(this.selectedComplaint.created_at).format('D/MM/YYYY');
    },
    map_center() {
      return {lat: this.selectedComplaint.location.latitude, lng: this.selectedComplaint.location.longitude}
    },
    zoom() {
      return this.$store.state.generalStore.mapCenter.zoom
    }
  },
  created() {
    console.log('map_center',this.map_center);
    console.log(this.$store.state.generalStore.mapCenter.zoom);
  }
};
</script>

<style lang="scss" scoped>
.title-container {
  background: transparent linear-gradient(180deg, #FFCB3E 0%, #F7B53F 55%, #EEC65C 100%) 0% 0% no-repeat padding-box;
  height: 80px;
  display: flex;
  align-items: center;
}
.marker-icon {
  width: 20px;
  height: auto;
  margin-right: 10px;
  margin-left: 20px;
}
.card-title {
  border-left: 2px solid #E50F1A;
  padding-left: 8px;
  font: normal normal 600 24px/18px Montserrat;
  height: 37px;
  display: flex;
  align-items: center;
}

.content {
  display: flex;
  gap: 16px;
  padding: 50px 20px;
}
.content-item {
  width: 100%;
  height: 300px;
}
@media (max-width: 1250px) {
    .content-item {
      width: 100%;
      height: 200px;
    }
  }
</style>
