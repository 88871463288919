<template>
	<v-navigation-drawer
	class="menu"
		permanent
		expand-on-hover
		absolute
		:mini-variant.sync="isMini"
	>
		<v-list>
			<v-list-item class="px-2" v-if="isMini">
				<v-list-item-avatar>
					<v-img :src="require('@/assets/images/logo-region.png')"></v-img>
				</v-list-item-avatar>
			</v-list-item>
			<v-list-item class="px-5 margin-auto" v-else>
				<v-img :src="require('@/assets/images/logo-region.png')" class="mx-auto" max-width="80"></v-img>
				<v-img :src="require('@/assets/images/logo-arica.png')" class="mx-auto" max-width="80"></v-img>
			</v-list-item>
		</v-list>

		<v-list nav dense>
			<router-link to="/reportes" class="router-link">
			<v-list-item link>
				<v-list-item-icon>
					<v-icon>mdi-view-grid</v-icon>
				</v-list-item-icon>
				<v-list-item-title>Reportes</v-list-item-title>
			</v-list-item>
				</router-link>
				<router-link to="/" class="router-link">
			<v-list-item link>
				<v-list-item-icon>
					<v-icon>mdi-view-grid</v-icon>
				</v-list-item-icon>
				<v-list-item-title>Geo</v-list-item-title>
			</v-list-item>
				</router-link>
		</v-list>
	</v-navigation-drawer>
</template>

<script>
export default {
	name: 'Menu',
  data () {
    return {
			isMini: true
    }
  }
}
</script>

<style scoped>
	.menu {
		background-color: #F7B53F;
	}
	.router-link {
		text-decoration: none;
	}
</style>