import complaintsStore from './complaintsStore.js'
import generalStore from './generalStore.js'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
	modules: {
		complaintsStore,
		generalStore
	}
})

export default store