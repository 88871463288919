const generalStore = {
  state: () => ({
			comuna_id: 99999,
			menuOpen: false,
			mapCenter: {center: { lat: -18.4746, lng: -70.29792 }, zoom:  12}
	}),
  mutations: {
	},
  actions: {
	}
}

export default generalStore