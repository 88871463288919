<template>
  <v-container fluid class="pa-0">
		<GmapMap
			:center="$store.state.generalStore.mapCenter.center" :zoom="$store.state.generalStore.mapCenter.zoom"
			:options="{ streetViewControl: false, mapTypeControl: false }"
			style="width: 100vw; height: 100vh"
		>
      <GmapCluster ref="markercluster" :averageCenter="true" :zoomOnClick="true" :ignoreHidden="true">
        <GmapMarker
          :key="index"
          :ref="index"
          v-for="(m, index) in $store.state.complaintsStore.complaints"
          :position="{ lat: m.location.latitude, lng: m.location.longitude }"
          :clickable="true"
          :icon="{ url: m.type.marker_icon, scaledSize: { width: 36, height: 60 } }"
          @click="selectMarker(m)"
        />
      </GmapCluster>
		</GmapMap>
    <v-dialog
      v-model="dialog"
      width="70%"
    >
        <videoPreview :selectedComplaint="selectedComplaint" :toggleDialog="toggleDialog"/>
    </v-dialog>
	</v-container>
</template>

<script>
import  videoPreview  from "../videoPreview.vue";

export default {
	name: 'MapScreen',
  components: {
    videoPreview
  },
  data() {
     return {
      selectedComplaint: {},
      dialog: false
     }
  },
methods: {
  toggleDialog() {
    this.dialog = !this.dialog
  },
  selectMarker(complaint) {
    this.selectedComplaint = complaint;
    this.toggleDialog()
  }
}
}
</script>

<style scoped>
  .v-dialog {
    border-radius: 15px !important;
  }
</style>