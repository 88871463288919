<template>
  <v-container class="bg">
    <div class="title-container">
      <h1 class="reportes-title">Reportes</h1>
    </div>
    <v-row class="reportes-container" v-if="$store.state.complaintsStore.complaints">
      <v-col
        v-for="complaint in $store.state.complaintsStore.complaints"
        :key="complaint.id"
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <v-card class="card" outlined tile max-width="400">
          <div class="card__container-video">
            <iframe
              class="video"
              width="100%"
              :src="complaint.videorecord.recording_link"
            ></iframe>
          </div>

          <div class="card__content">
            <img
              class="marker-icon"
              :src="complaint.type.marker_icon"
              alt="videorecord"
            />

            <div>
              <h3 class="card__content__title">{{ complaint.type.title }}</h3>
              <p class="card__content__subtitle">
                {{ complaint.created_at | formatDate }}
              </p>
            </div>
          </div>

          <v-card-actions>
            <button class="card__btn-report" @click="selectReport(complaint)">
              Ver Reporte
            </button>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <h4 v-if="!$store.state.complaintsStore.complaints.length && $store.state.complaintsStore.filter">No hay reportes disponibles.</h4>

    <v-dialog v-model="dialog" width="80%">
      <videoPreview
      v-if="dialog"
        :selectedComplaint="selectedComplaint"
        :toggleDialog="toggleDialog"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import videoPreview from "../components/videoPreview.vue";
import moment from "moment";

export default {
  components: {
    videoPreview,
  },
  data() {
    return {
      selectedComplaint: {},
      dialog: false,
    };
  },
  filters: {
    formatDate: function (value) {
      return moment(value).format("D/MM/YYYY - h:mm:ss a");
    },
  },
  methods: {
    toggleDialog() {
      this.dialog = !this.dialog;
    },
    selectReport(complaint) {
      this.selectedComplaint = complaint;
      this.toggleDialog();
    },
  },
};
</script>

<style lang="scss" scoped>
.bg {
  background-color: #f7f7f8;
}
.reportes-container {
  padding: 50px;
}
.title-container {
  background: #fff;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 12px;
  margin-left: 50px;
  margin-right: 50px;
  border: 1px solid #E4E4E4;
    border-radius: 10px;

}
.reportes-title {
  color: #363a36;
  margin-left: 24px;
  font: normal normal bold 35px/43px Montserrat;
}
.marker-icon {
  width: 15px;
  height: auto;
  margin-right: 10px;
  margin-left: 20px;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 166px;
}

.card {
  &__container-video {
    width: 100%;
      background-color: #F7B53F;
      height: 166px;
  }
  &__content {
    display: flex;
    align-items: center;
    &__title {
      margin-top: 12px;
      color: #151f33;
      font: normal normal 600 16px/18px Montserrat;
    }
    &__subtitle {
      margin-top: 6px;
      font: normal normal normal 14px/18px Montserrat;
      color: #151f33;
    }
  }
  &__btn-report {
    background: transparent linear-gradient(180deg, #ff6200 0%, #e50f1a 100%) 0% 0% no-repeat padding-box;
    border-radius: 7px;
    opacity: 1;
    top: 410px;
    left: 339px;
    width: 123px;
    height: 37px;
    font: normal normal normal 14px/18px Montserrat;
    color: #fff;
    margin-left: 8px;
  }
}

@media(max-width: 600px) {
    .card {
        width: 100%;
        &__btn-report {
            width: 100%;
        }
    }
}
</style>
