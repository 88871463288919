<template>
  <v-container fluid class="pa-0">
		<Menu />
			<Filters />
		<router-view>
			<MapScreen />
		</router-view>
		<!-- <GeoMap ref="geomap"/> -->
	</v-container>
</template>

<script>
import Menu from '@/components/Layout/Menu'
import Filters from '@/components/Layout/Filters'
 import MapScreen from '@/components/MapComplaints/MapScreen'
//import GeoMap from '@/components/Geo'

export default {
	name: 'WebContainer',
	components: {MapScreen, Menu, Filters, /* GeoMap */},
	mounted(){
	},
	methods:{
	}
}
</script>

<style>

</style>