var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.selectedComplaint)?_c('div',[_c('v-card',{staticStyle:{"border-radius":"10px"}},[_c('div',{staticClass:"title-container"},[_c('img',{staticClass:"marker-icon",attrs:{"src":_vm.selectedComplaint.type.marker_icon,"alt":"videorecord"}}),_c('h5',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.selectedComplaint.type.title)+" ")]),_c('v-btn',{staticClass:"ml-auto mr-5",attrs:{"icon":""},on:{"click":_vm.toggleDialog}},[_c('v-icon',{staticStyle:{"font-size":"44px","color":"black"}},[_vm._v(" mdi-close-circle-outline ")])],1)],1),_c('section',{staticClass:"content"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('iframe',{staticClass:"content-item",attrs:{"src":_vm.selectedComplaint.videorecord.recording_link,"frameborder":"0","allowfullscreen":""}})]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('GmapMap',{staticClass:"content-item",attrs:{"center":_vm.map_center,"zoom":_vm.$store.state.generalStore.mapCenter.zoom,"options":{ streetViewControl: false, mapTypeControl: false }}},[_c('GmapCluster',{ref:"markercluster",attrs:{"averageCenter":true,"zoomOnClick":true,"ignoreHidden":true}},[_c('GmapMarker',{attrs:{"position":{
              lat: _vm.selectedComplaint.location.latitude,
              lng: _vm.selectedComplaint.location.longitude,
            },"clickable":true,"icon":{
              url: _vm.selectedComplaint.type.marker_icon,
              scaledSize: { width: 36, height: 60 },
            }}})],1)],1)],1)],1)],1)])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }